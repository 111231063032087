import { useGlobalContext } from "../context/GlobalContext";

import DownloadButtons from "./DownloadButtons";
import styles from "./styles/Modal.module.scss";

import type { Dictionary } from "~/utils/dictionaries";

export default function PlayNowModal({ locale, copy }: { locale: string; copy: Dictionary }): React.ReactNode {
	const { popup, setPopup } = useGlobalContext();

	function handleClick(): void {
		setPopup(false);
	}

	return (
		<div
			id="modal"
			className={`modal play-now-modal ${styles.modalBg}`}
			style={{ display: popup ? "flex" : "none" }}
			onClick={handleClick}
			onKeyDown={handleClick}
			role="presentation"
		>
			<div className={`modal-background`}></div>
			<div className="modal-card has-text-centered">
				<div className="modal-content">
					<h1 className="article-title title is-size-1 my-6">{copy.popup.title}</h1>
					<div className="columns is-centered">
						<div className="column is-three-quarters">
							<DownloadButtons locale={locale} />
						</div>
					</div>
				</div>
				<button
					onClick={handleClick}
					onKeyDown={handleClick}
					className={`modal-close ${styles.modalClose}`}
					aria-label="close"
				></button>
			</div>
		</div>
	);
}
