// import Link from 'next/link';
// import Image from 'next/image';
import { Link } from "@remix-run/react";

interface DownloadButtonProps {
	name: string;
	customClass: string;
	targetUrl: string;
	imageUrl: string;
}

export default function DownloadButton(data: DownloadButtonProps): React.ReactNode {
	return (
		<Link className={`download-button`} to={data.targetUrl}>
			<img className={data.customClass} src={data.imageUrl} alt={data.name} width={190} height={53} />
		</Link>
	);
}
